// NOTE: These files should not have any more changes made to them
// before they are removed. If this poses a problem contact the front
// end engineering manager
define(['jquery', 'siteObj'], function($, siteObj) {
  
  $.fn.countdownTimer = function(options) {

    var defaults = {
        parentHide: false, // Set the class of a different parent element if you want to hide from this level instead.
        cutOffTime: null, // The cut off time for the countdown timer. Default is null and will not show the timer.
        serverTime: null, // Use this if you want to pass in the server time.
        format: 'HMS', // Set the format of the clock, default is HMS where H = Hours, M = Minutes and S = Seconds. Days are also available as D.
        seperator: ':', // The separator to go in-between the numbers when labels are switched off.
        showLabels: false, // This is the option where you can choose whether to have labels on or off.
        labels: ['Days', 'Hours', 'Minutes', 'Seconds'], // The default labels used when they are switched on.
        timerClass: '' // Here you can add a custom class to the <p> which wraps the countdown time
      },
      D = 0, // Days
      H = 1, // Hours
      M = 2, // Minutes
      S = 3, // Seconds
      $wrapper,
      _utils = {

        times: [0, 0, 0, 0],

        _processTimes: function(difference) {
          this.times[D] = Math.floor(difference / 1000 / 60 / 60 / 24); // Work out the days remaining
          this.times[H] = Math.floor(difference / 1000 / 60 / 60 % 24); // Work out the hours remaining
          this.times[M] = Math.floor(difference / 1000 / 60 % 60); // Work out the minutes remaining
          this.times[S] = Math.floor(difference / 1000 % 60); // Work out the seconds remaining
          for (var i = 0; i < this.times.length; i++) { // This adds a leading 0 to any numbers that are less than 10 so that they are formatted correctly
            if (this.times[i] < 10) {
              this.times[i] = '0' + this.times[i];
            }
          }
        },

        _build_show: function() {
          var format = options.format,
            show = [];

          show[D] = (format.match('D') ? 'show' : null);
          show[H] = (format.match('H') ? 'show' : null);
          show[M] = (format.match('M') ? 'show' : null);
          show[S] = (format.match('S') ? 'show' : null);
          return show;
        },

        _build_time: function() {
          var seperator = options.seperator,
            show = this._build_show(),
            labels = options.labels,
            timerClass = options.timerClass,
            html = '<div class="countdown-time ' + timerClass + '">';

          if (options.showLabels === true) {
            if (show[D]) {
              html += '<div class="countdown-block"><span class="countdown-amount">' + this.times[D] + '</span><span class="countdown-label">' + labels[D] + '</span></div>';
            }
            if (show[H]) {
              html += '<div class="countdown-block"><span class="countdown-amount">' + this.times[H] + '</span><span class="countdown-label">' + labels[H] + '</span></div>';
            }
            if (show[M]) {
              html += '<div class="countdown-block"><span class="countdown-amount">' + this.times[M] + '</span><span class="countdown-label">' + labels[M] + '</span></div>';
            }
            if (show[S]) {
              html += '<div class="countdown-block"><span class="countdown-amount">' + this.times[S] + '</span><span class="countdown-label">' + labels[S] + '</span></div>';
            }
          } else {
            if (show[D]) {
              //html += this.times[D];
              html += '<span class="countdown-amount">' + this.times[D] + '</span>';
              if (show[D] && show[H]) {
                html += '<span class="countdown-seperator">' + seperator + '</span>';
              }
            }
            if (show[H] && show[M]) {
              //html += this.times[H] + seperator;
              html += '<span class="countdown-amount">' + this.times[H] + '</span>' + '<span class="countdown-seperator">' + seperator + '</span>';
            }
            if (show[M] && show[S]) {
              //html += this.times[M] + seperator;
              html += '<span class="countdown-amount">' + this.times[M] + '</span>' + '<span class="countdown-seperator">' + seperator + '</span>';
            }
            if (show[S]) {
              //html += this.times[S];
              html += '<span class="countdown-amount">' + this.times[S] + '</span>';
            }
          }
          html += '</div>';
          return html;
        },

        _render_html: function(countdown) {
          var html = this._build_time();
          countdown.html(html);
        }
      };

    options = $.extend(defaults, options);

    return this.each(function() {

      var $this = $(this),
        currentTime = options.serverTime || new Date(),
        difference = options.cutOffTime - currentTime, //Find the difference in the target time and the current time.
        update_timer = function() {
          if ((difference >= 1000) && (options.cutOffTime !== null)) {
            $wrapper.show();
            difference = difference - 1000; // Take off 1 second from the original time.
            _utils._processTimes(difference);
            _utils._render_html($this);
          // siteObj.fn.log(_utils.times)
          } else {
            $wrapper.hide();
            clearInterval(update_timer);
          }
        };

      if (options.parentHide.length) {
        $wrapper = $('.' + options.parentHide);
      } else {
        $wrapper = $this;
      }
      $wrapper.hide();
      if (siteObj.fn && siteObj.fn.log) {
        siteObj.fn.log(currentTime + ' & ' + options.cutOffTime + ' & ' + difference);
      }

      window.setInterval(update_timer, 1000);
    });
  };
});
